/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 var body="html, body";
 var altoIframe=0;

function igualaAlturas(_itm){
  
  var alt=0;

  jQuery(_itm).each(function(){

    alt = Math.max(alt, jQuery(this).height() );
 

  });


  jQuery(_itm).height(alt);

  console.log(alt);

} 

function igualaAnchos(_itm){
  
  var anch=0;

  jQuery(_itm).each(function(){

    anch = Math.max(anch, jQuery(this).width() );
 

  });


  jQuery(_itm).width(anch);

  console.log(anch);

} 

function igualaAltParent(_itm){



  jQuery(_itm).each(function(){

    jQuery(this).css('height', 'auto');

    //jQuery(this).height( jQuery(this).parent().height() );
    jQuery(this).css('height', jQuery(this).parent().height() );
 

  });

}


function igualaAltoAncho(_itm){


  jQuery(_itm).css('height','auto');


  jQuery(_itm).each(function(){

    console.log(jQuery(this).width());

    jQuery(this).height(jQuery(this).width());

  });

}

function resize(){

/*

  if(  (jQuery('.wrap').outerHeight(true)  + jQuery('.footer').outerHeight(true)) < jQuery(window).height() ){

      jQuery('.wrap').height(   jQuery(window).height() - jQuery('.footer').outerHeight(true) - parseInt(jQuery('.wrap').css('margin-top')  )   );

  }
*/


jQuery('.wrap').css('height', 'auto');

  var altoCont = jQuery('.wrap').outerHeight(true) + jQuery('footer').outerHeight(true);

  if( altoCont<jQuery(window).height()   ){

      jQuery('.wrap').outerHeight(   jQuery(window).height() - jQuery('footer').outerHeight(true)  );

  }



}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        //CAPTURO URL

        function _capLink(ev){
          var strURL = $(this).attr('href');
          var noTab = strURL.indexOf("/");

          jQuery('.tapa_blanca').css('bottom', 0 );
          jQuery('.tapa_blanca').css('top', 0 );


          if(   $(this).attr('target') =="_blank"  ||  $(this).hasClass('noLink')      || $(this).hasClass('votebutton')    ){
 

          }else{
            ev.preventDefault();

            jQuery('.tapa_blanca').fadeIn(400, function(){

              window.location.href = strURL;

            });
          }


        }

        jQuery('a').on('click', _capLink);



        $('.bot_menu_movil').on('click',function(ev){

          ev.preventDefault();
          $('.nav-primary').slideToggle(300);

        });
/*

        if($('.swiper-home') ){ 

          var swiperHome = new Swiper('.swiper-home', {
              autoplay: {
                delay: 2500,
                disableOnInteraction: false,
              },
              speed:1000, 
              loop:true,
              slidesPerView: 1, 
              effect:'fade',
              fade: {
                crossFade: false
              }
          }); 

        }

        if($('.swiper-news') ){ 

          var swiperNews = new Swiper('.swiper-news', {
              autoplay: {
                delay: 2600,
                disableOnInteraction: true,
              },
              speed:1000, 
              loop:true,
              slidesPerView: 1 ,
              spaceBetween: 10,

            navigation: {
                  nextEl: '.swiper-news-next',
                  prevEl: '.swiper-news-prev',
                },

 
          }); 

        }

        */


        if($('.tot_espec') ){ 

          var slidesPV = 3;

          if($('.tot_espec .swiper-slide').length<3){
            slidesPV = 2;
          }

            var swiperEspec = new Swiper('.tot_espec .swiper-espec', {
                autoplay:5000,
                speed:1000, 
                disableOnInteraction:false, 
                slidesPerView: slidesPV,

                breakpoints: {
                  // when window width is <= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  // when window width is <= 480px
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  // when window width is <= 640px
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 1
                  }
                },


                navigation: {
                  nextEl: '.tot_espec .swip_espec.swiper-button-next',
                  prevEl: '.tot_espec .swip_espec.swiper-button-prev',
                },
                spaceBetween:1,
             
            });      

        }


        if($('.espec_gira') ){ 

            //alert("!!!!")     ;

          var slidesPV = 3;

          if($('.espec_gira .swiper-slide').length<3){
            slidesPV = 2;
          }

            var swiperNoGira = new Swiper('.espec_gira .swiper-espec', {
                autoplay:5000,
                speed:1000, 
                disableOnInteraction:false, 
                slidesPerView: slidesPV,

                breakpoints: {
                  // when window width is <= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  // when window width is <= 480px
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  // when window width is <= 640px
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 1
                  }
                },


                navigation: {
                  nextEl: '.espec_gira .swip_espec.swiper-button-next',
                  prevEl: '.espec_gira .swip_espec.swiper-button-prev',
                },
                spaceBetween:1,
             
            }); 



        }


//

        if($('.swiper-galeria') ){ 
            var swiperGal = new Swiper('.swiper-galeria', {
                autoplay:5000,
                speed:1000, 
                disableOnInteraction:false, 
                slidesPerView: 1,
                navigation: {
                  nextEl: '.swip_galeria.swiper-button-next',
                  prevEl: '.swip_galeria.swiper-button-prev',
                },
                spaceBetween:0,
             
            });      

            $('.swiper-galeria .swiper-slide iframe').each(function(){

//              $(this).height($(this).parent.)
                
                altoIframe = $('.swiper-galeria .swiper-slide:last-child').height() ; 
                $(this).height( $('.swiper-galeria .swiper-slide:last-child').height() );

            });
            $('.swiper-galeria .swiper-slide iframe').each(function(){

//              $(this).height($(this).parent.)
                  $(this).height( $('.swiper-galeria .swiper-slide:last-child').height() );

            });

            swiperGal.on('slideChange', function(){


              if($('.swiper-slide-active').hasClass('swiper_video') ){
                console.log( $('.swiper-slide-active').attr('class') );
                //console.log("YEAH! " + $(this).attr('class')+" / "+ $(this).find('iframe').attr('src')  );
                var miSource = $('.swiper-slide-active').find('iframe').attr('data-source') 
                $('.swiper-slide-active').find('iframe').attr('src',''); 
                $('.swiper-slide-active').find('iframe').attr( 'src', miSource);
              }

            });


        }

        jQuery('.next_prev').slideUp();

        if(jQuery(window).width()>768){

           jQuery('.wrap').css('padding-top', jQuery('.banner').outerHeight(true));         
            
        }




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


window.onload=function(){

  jQuery('html').scrollWithEase();




      var miScroll = jQuery('.img_parallax').css('top');              

      miScroll = parseInt(miScroll);

      jQuery(window).on('scroll', function(ev){

          scrollTop = jQuery(window).scrollTop();

            /*
          if(scrollTop >  jQuery('.regla').offset().top    ){

            //  jQuery('.banner').addClass('minibar');
         // jQuery('.main').css('padding-top', jQuery('.banner').outerHeight(true) + jQuery('.backPunt').outerHeight(true) );

         if(!jQuery('.banner').hasClass('minibar_nu')){
            //jQuery('.main').css('padding-top', jQuery('.banner').outerHeight(true)  );
          }
          //alert(jQuery('.banner').outerHeight(true));
          jQuery('.banner').addClass('minibar_nu');

          jQuery('.mini_menu').addClass('show_mini_menu')



          }else{

          //  jQuery('.banner').removeClass('minibar');
          //jQuery('.main').css('padding-top', 0 );
          jQuery('.banner').removeClass('minibar_nu');
          jQuery('.mini_menu').removeClass('show_mini_menu')

          }
    */


          //PARALLAX

            if(jQuery('.img_parallax').length>0){  
  
              console.log( miScroll+" / "+ ((scrollTop/10)  * -1));

              jQuery('.img_parallax').css('top' , (miScroll + (scrollTop/10)  * -1) );
 
            }


            //FIN BOTTOM

            if(jQuery('.next_prev') ){

                   if(jQuery(window).scrollTop() + jQuery(window).height() == jQuery(document).height()) {
                         console.log("bottom!");

                         if( !(jQuery('.next_prev').hasClass('abierto')) ){

                          
                          jQuery('.next_prev').stop().slideDown(400, function(){  

                            jQuery('.next_prev').addClass('abierto');

                          });

                        }
                     }else{
      

                        console.log("NO bottom!");

                      if( jQuery('.next_prev').hasClass('abierto') ){
                       /* jQuery('.next_prev').stop().slideUp(200, function(){

                          jQuery('.next_prev').removeClass('abierto');

                        });*/
                       
                        

                      }


                     }

            }





      });


resize();
/**/



        if(altoIframe < 100){

                        
                   // altoIframe = jQuery('.swiper-galeria .swiper-slide:last-child').height() ; 
                   // $(this).height( jQuery('.swiper-galeria .swiper-slide:last-child').height() );


                    jQuery('.swiper-galeria .swiper-slide iframe').each(function(){

        //              $(this).height($(this).parent.)
                        
                        altoIframe = jQuery('.swiper-galeria .swiper-slide:last-child').height() ; 
                        jQuery(this).height( jQuery('.swiper-galeria .swiper-slide:last-child').height() );

                    });


        }




        if(jQuery('.swiper-home') ){ 

          var swiperHome = new Swiper('.swiper-home', {
              autoplay: {
                delay: 2500,
                disableOnInteraction: false,
              },


              speed:1000, 
              loop:true,
              slidesPerView: 1, 
              effect:'fade',
              fade: {
                crossFade: true
              }
          }); 

        }

        if(jQuery('.swiper-news') ){ 

          var swiperNews = new Swiper('.swiper-news', {
              autoplay: {
                delay: 2600,
                disableOnInteraction: true,
              },
              speed:1000, 
              loop:true,
              slidesPerView: 1 ,
              spaceBetween: 10,

            navigation: {
                  nextEl: '.swiper-news-next',
                  prevEl: '.swiper-news-prev',
                },

 
          }); 

        }

jQuery('.tapa_blanca').fadeOut(400, function(){

  //jQuery('.tapa_blanca').remove();

});

}


jQuery(window).on('resize', function(){
  resize();
});